import React from 'react';
import * as Icon from 'react-feather';

// Client Images
import Client1 from '../../assets/images/client-image/noimage.png'
import Client2 from '../../assets/images/client-image/noimage.png'
import Client3 from '../../assets/images/client-image/noimage.png'

// Shape Images
import AgencyUser1 from "../../assets/images/agency-image/agency-user1.png";
import AgencyUser2 from "../../assets/images/agency-image/ayse-abla.png";
import AgencyUser3 from "../../assets/images/agency-image/agency-user3.png";
import AgencyUser4 from "../../assets/images/agency-image/ahmet-abi.png";
import AgencyUser5 from "../../assets/images/agency-image/faruk-bey.png";
import AgencyUser6 from "../../assets/images/agency-image/ismet-fabrika.png";

import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: false,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    items: 1,
    animateOut: 'fadeOut',
}

const FeedbackStyleThree = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="ml-feedback-area ptb-80">
            <div className="container">
                <div className="section-title st-fs-28">
                    <span className="sub-title"></span>
                    <h2>Yorumlar</h2>
                    {/* <div className="bar"></div> */}
                    <p></p>
                </div>

                {display ? <OwlCarousel 
                    className="ml-feedback-slides owl-carousel owl-theme"
                    {...options}
                >  
                    <div className="single-testimonials-item">
                        <p>Akülü araba ihtiyacım vardı. Kullanmadığım orta sehpa, lcd tv, bozuk laptop ve döner koltukla takas ettim </p>
                        <p>Kızım akülü arabasından çok memnun</p>
                        <div className="client-info">
                            <img src={Client1} alt="image" />

                            <div className="rating">
                            
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                                
                            </div>

                            <h3>S** T**</h3>
                            
                        </div> 
                    </div>

                    <div className="single-testimonials-item">
                        <p>Kondisyon bisikleti ile laptop u takas ettim. Kondisyon bisikletini kurup çalıştırıp teslim ettiler.</p>
                        <div className="client-info">
                            <img src={Client2} alt="image" />

                            <div className="rating">
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                            </div>

                            <h3>S** S**</h3>
                            <span></span>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>Tupperware saklama kabı ile hediye gelen kutusu açılmamış kullanmadığım çaydaklık takımı ve tencere ile takas ettim.</p>

                        <div className="client-info">
                            <img src={Client3} alt="image" />

                            <div className="rating">
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                                <Icon.Star />
                            </div>

                            <h3>H** E**</h3>
                            
                        </div>
                    </div>
                </OwlCarousel> : ''}
            </div>

            {/* Shape Images */}
            <div className="user-shape1">
                <img src={AgencyUser1} alt="shape" />
            </div>
            <div className="user-shape2">
                <img src={AgencyUser2} alt="shape" />
            </div>
            <div className="user-shape3">
                <img src={AgencyUser3} alt="shape" />
            </div>
            <div className="user-shape4">
                <img src={AgencyUser4} alt="shape" />
            </div>
            <div className="user-shape5">
                <img src={AgencyUser5} alt="shape" />
            </div>
            <div className="user-shape6">
                <img src={AgencyUser6} alt="shape" />
            </div>
        </div>
    );
}

export default FeedbackStyleThree;